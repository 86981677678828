$(document).ready(function() {
  $(".fair_menu_rm").on('click',function(){
    id_menu = document.getElementById('fairs_nav')
    id_menu_inside = document.getElementById('fairs_mega_menu')

    if(id_menu){
      id_menu.classList.remove("show")
      if(id_menu_inside){
        id_menu_inside.classList.remove("show")
      }
    }
  });  

  $(".t_shop_menu_rm").on('click',function(){
    id_menu = document.getElementById('shop_nav')
    id_menu_inside = document.getElementById('shop_mega_menu')

    if(id_menu){
      id_menu.classList.remove("show")
      if(id_menu_inside){
        id_menu_inside.classList.remove("show")
      }
    }
  });  
});